import _ from 'lodash';
<template>
    <v-card>
        <v-card-text>
            <div class="py-4">
                <h3>
                    LOAD FILES
                    {{ parseInt(accumulated) || 0 }}
                    of {{ numberOfFiles }}
                </h3>
            </div>
            <v-progress-linear v-model="totalAccumulated" height="25">
                <strong>{{ Math.ceil(totalAccumulated) }}%</strong>
            </v-progress-linear>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'LoadFiles',
    props: {
        numberOfFiles: {
            type: Number,
            default: () => 0,
        },
        accumulated: {
            type: Number,
            default: () => 0,
        },
    },
    data: () => ({
        loading: false,
        percentage: 0,
        totalAccumulated: 0,
    }),
    watch: {
        accumulated() {
            this.totalAccumulated += this.accumulated * this.percentage
        },
    },
    mounted() {
        this.percentage = parseInt(100 / this.numberOfFiles)
    },
}
</script>

<style></style>
